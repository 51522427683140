<template>
  <div class="container">
    <Header :active="5" />
    <div class="banner-box">
      <el-image
        class="banner-box-img"
        src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/innovation/banner.png"
        lazy
      ></el-image>
      <div class="banner-box-text-box">
        <div class="banner-box-text-box-title">
          <div>1CARE高明之处</div>
        </div>
        <div class="banner-box-text-box-tips">1CARE brilliant</div>
        <div
          class="horizontal-line"
          :style="{ margin: pxToRem([30, 0]) }"
        ></div>
        <div class="banner-box-text-box-info">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1CARE是由医学专家、护理专家、看护者，为我们年迈的亲人打造的智慧健康监测平台。
        </div>
        <el-image
          class="banner-box-text-box-btn"
          src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/innovation/banner-btn.png"
          lazy
        ></el-image>
        <!-- <div class="banner-box-text-box-txt">
          * 家庭医生/护理人员/公司高管： 解释1CARE在现实场景中的应用、功能。
        </div> -->
      </div>
    </div>
    <div class="section1">
      <div class="section1-box">
        <div class="section1-box-left">
          <el-image
            src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/innovation/section1-img1.png"
            lazy
          ></el-image>
        </div>
        <div class="section1-box-right">
          <div class="section1-box-right-title">改善老年护理</div>
          <div class="section1-box-right-subtitle">
            Improve care for the elderly
          </div>
          <div class="horizontal-line m-t-16"></div>
          <div class="section1-box-right-info">
            <div class="section1-box-right-info-box">
              <div class="section1-box-right-info-box-circle"></div>
              <div class="section1-box-right-info-box-text">
                随着年龄的变化，我们的机体以及日常生活活动都会发生一些渐进的、不可避免的细微变化，这些细微的变化过程，可能蕴藏着健康恶化的苗头；
              </div>
            </div>
            <div class="section1-box-right-info-box">
              <div class="section1-box-right-info-box-circle"></div>
              <div class="section1-box-right-info-box-text">
                在这些细微的变化出现时立即捕捉到它们，以便在健康出现变化之前及时采取行动；但生活中仅凭我们的肉眼是无法观察到这些变化，1CARE多模融合智能传感器——可以在健康出现危机前感知到它，并改善或消除这些不可预测的风险；
              </div>
            </div>
            <div class="section1-box-right-info-box">
              <div class="section1-box-right-info-box-circle"></div>
              <div class="section1-box-right-info-box-text">
                目前的养老监测技术多重视结局，而不是为防止或延缓结局发生过程；比如灯关了，但是无法预测到谁会去关，通常什么时间去关；这些细微的变化，可能显示我们的功能状态已经发生了细微变化；
              </div>
            </div>
            <div class="section1-box-right-info-box">
              <div class="section1-box-right-info-box-circle"></div>
              <div class="section1-box-right-info-box-text">
                可穿戴消费设备已经迈进个性化时代，但步数、脉搏等监测信息，不足以揭示我们日常生活模式有意义的变化，它们缺乏历史数据，只能跟踪有限的活动；
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section1-box m-t-217">
        <div class="section1-box-right">
          <div class="section1-box-right-title">日常生活活动（ADL）感知</div>
          <div class="section1-box-right-subtitle">
            Perception of activities of daily living (ADL)
          </div>
          <div class="horizontal-line m-t-16"></div>
          <div class="section1-box-right-info">
            <div class="section1-box-right-info-box">
              <div class="section1-box-right-info-box-circle"></div>
              <div class="section1-box-right-info-box-text">
                日常的生活活动构成了我们的生活模式，例如就餐、喝水、洗澡、入厕、走路、静坐、睡觉等等；这些模式在身体健康出现问题之前会发生难以观察到的细微变化；
              </div>
            </div>
            <div class="section1-box-right-info-box">
              <div class="section1-box-right-info-box-circle"></div>
              <div class="section1-box-right-info-box-text">
                1CARE是一款时尚腕戴式可穿戴设备，其中置入了一系列复杂、先进的智能传感器，可融合感知生活活动变化；
              </div>
            </div>
            <div class="section1-box-right-info-box">
              <div class="section1-box-right-info-box-circle"></div>
              <div class="section1-box-right-info-box-text">
                1CARE可感知日常生活活动的细微变化，比如活动的异常重复、浴室使用或静坐行为增加等；1CARE感知并通过AI深度学习算法来报告这些可能导致健康问题的变化；
              </div>
            </div>
            <div class="section1-box-right-info-box">
              <div class="section1-box-right-info-box-circle"></div>
              <div class="section1-box-right-info-box-text">
                通过深入了解人们的日常生活活动模式，以及这些模式中的细微变化，1CARE提供了强大的趋势分析，对将要发生的健康或功能状态下降做出提前预测；
              </div>
            </div>
            <div class="section1-box-right-info-box">
              <div class="section1-box-right-info-box-circle"></div>
              <div class="section1-box-right-info-box-text">
                1CARE利用多模融合智能感知技术，并通过AI赋能，识别可能导致疾病的模式，例如跌倒风险、尿路感染、糖尿病、营养不良和抑郁症等；
              </div>
            </div>
          </div>
        </div>
        <div class="section1-box-left">
          <el-image
            src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/innovation/section1-img2.png"
            lazy
          ></el-image>
        </div>
      </div>
    </div>
    <div class="section2">
      <div class="section2-box">
        <div class="title-box">
          <div class="title">赋予护理更高的智慧化水平</div>
          <div class="subtitle" :style="{ padding: pxToRem([10, 0]) }">
            Give nursing higher intelligent level
          </div>
          <div class="horizontal-line m-t-16"></div>
          <div class="section2-box-info">
            主动为护理和管理人员提供可操作的专业见解，让老人及其亲人安心！
          </div>
        </div>
        <div class="section2-box-content">
          <el-image
            class="section2-box-content-img"
            src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/innovation/section2-img1.png"
            lazy
          ></el-image>
          <div class="section2-box-content-info">
            <div class="section2-box-content-info-title">
              <span>端到端解决方案</span>
              <el-image
                class="section2-box-content-info-title-img"
                src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/icon/arrow-left.png"
                lazy
              ></el-image>
            </div>
            <div class="section2-box-content-info-text">
              &nbsp;&nbsp;&nbsp;&nbsp;1CARE为您提供可操作的建议，以帮助您提供更好的智慧化护理水平；该综合解决方案融合了智能感知、位置跟踪、视频识别、
              AI赋能等多模智能技术；
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section3">
      <div class="section2-box-content">
        <div class="section2-box-content-info m-t-180">
          <div
            class="section2-box-content-info-title"
            style="justify-content: flex-end"
          >
            <span>1CARE可穿戴设备</span>
            <el-image
              class="section2-box-content-info-title-img"
              src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/icon/arrow-left.png"
              lazy
            ></el-image>
          </div>
          <div class="section2-box-content-info-text w-655">
            &nbsp;&nbsp;&nbsp;&nbsp;1CARE健康是一套包括腕戴式可穿戴设备、定位信标等高端硬件系统，内置一系列复杂的智能传感器，能够检测体征信息和日常生活活动信息，有触摸式SOS呼叫系统，可与护理人员或亲属实时通信；例如，1CARE可感知心率、体温、吃饭、喝水、洗澡、如厕、运动、睡觉等健康和生活状态，并知道这些活动发生的具体位置，当按下紧急呼叫按钮时，1CARE会向护理人员或亲属发送警报，并显示确切位置；1CARE还可以与
            RFID
            芯片集成以实现无钥匙智能开门、支付等功能，让老人保持与社会的全时接触；
          </div>
        </div>
        <el-image
          class="section2-box-content-img w-405 h-505"
          src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/innovation/section2-img2.png"
          lazy
        ></el-image>
      </div>
    </div>
    <div class="section4">
      <el-image
        class="section4-img"
        src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/innovation/section4-img11.png"
        lazy
      ></el-image>
      <div class="section4-box">
        <div class="section4-box-title-box">
          <div class="section4-box-title-box-title">
            智慧护理，赋予老人更美好的健康生活
          </div>
          <div class="section4-box-title-box-subtitle">
            Intelligent nursing，Give the elderly a better and healthy life
          </div>
          <div
            class="horizontal-line"
            :style="{ margin: '0 auto', marginTop: pxToRem(49) }"
          ></div>
        </div>
        <div class="section4-box-card" v-if="!sectionFourObj">
          <div
            v-for="(item, index) in section4List"
            :key="item.img"
            class="section4-box-card-item"
            @click="handleShowDetail(index)"
          >
            <el-image
              :src="item.img"
              :style="{
                width: pxToRem(item.width),
                height: pxToRem(item.height),
              }"
              lazy
            ></el-image>
          </div>
        </div>
        <div class="sectionItemBig" @click="handleHideDetail" v-else>
          <div class="itemLeft">
            <img :src="sectionFourObj.img" alt="" />
            <!-- <p>{{ sectionFourObj.text1 }}</p> -->
          </div>
          <div class="itemRight">
            <p v-for="(el, ind) in sectionFourObj.textArr" :key="ind">
              {{ el }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="section5">
      <div class="section5-box">
        <div class="section4-box-title-box">
          <div class="section4-box-title-box-title">
            让感知更加“专业化、智慧化”
          </div>
          <div class="section4-box-title-box-subtitle">
            Make perception more"Professional and intelligent"
          </div>
          <div
            class="horizontal-line"
            :style="{ margin: '0 auto', marginTop: pxToRem(49) }"
          ></div>
        </div>
        <div class="section5-box-content">
          <div class="section5-box-content-left">
            <!-- <el-image
              src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/innovation/section5-img1.png"
              style="width: 100%; height: 100%"
              lazy
            ></el-image> -->
            <el-carousel
              ref="carousel"
              :autoplay="false"
              trigger="click"
              height="2.3125rem"
            >
              <el-carousel-item
                v-for="(item, index) in section5List"
                :key="index"
              >
                <div class="section5-box-content-left-item">
                  <el-image
                    :src="item.img"
                    style="
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      z-index: -1;
                    "
                  ></el-image>
                  <div class="section5-box-content-left-item-box">
                    <div
                      class="section5-box-content-left-item-box-title"
                      :style="{ color: index == 1 ? '#1f3876' : '' }"
                    >
                      {{ item.title }}
                    </div>
                    <!-- <div class="section5-box-content-left-item-box-subtitle">
                      {{ item.subtitle }}
                    </div> -->
                    <div class="section5-box-content-left-item-box-info">
                      <div
                        class="section5-box-content-left-item-box-info-item"
                        :style="{ color: index == 1 ? '#1f3876' : '' }"
                        v-for="it in item.textArr"
                        :key="it"
                      >
                        <div
                          class="section5-box-content-left-item-box-info-item-circle"
                        ></div>
                        <div>{{ it }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="section5-box-content-right">
            <div
              :class="[
                carouselIndex == 0 ? 'section5-box-content-right-active' : '',
                'section5-box-content-right-item',
              ]"
              @click="changeCarousel(0)"
            >
              定位引擎
              <div
                v-if="carouselIndex != 0"
                class="section5-box-content-right-item-bottom"
              ></div>
            </div>
            <div
              :class="[
                carouselIndex == 1 ? 'section5-box-content-right-active' : '',
                'section5-box-content-right-item',
              ]"
              @click="changeCarousel(1)"
            >
              地图引擎
              <div
                v-if="carouselIndex != 1"
                class="section5-box-content-right-item-bottom"
              ></div>
            </div>
            <div
              :class="[
                carouselIndex == 2 ? 'section5-box-content-right-active' : '',
                'section5-box-content-right-item',
              ]"
              @click="changeCarousel(2)"
            >
              业务引擎
              <div
                v-if="carouselIndex != 2"
                class="section5-box-content-right-item-bottom"
              ></div>
            </div>
            <div
              :class="[
                carouselIndex == 3 ? 'section5-box-content-right-active' : '',
                'section5-box-content-right-item',
              ]"
              @click="changeCarousel(3)"
            >
              AI算法引擎
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "innovation",
  data() {
    return {
      section4List: [
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/innovation/section4-img1.png",
          width: 86,
          height: 114,
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/innovation/section4-img2.png",
          width: 86,
          height: 114,
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/innovation/section4-img3.png",
          width: 92,
          height: 114,
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/innovation/section4-img4.png",
          width: 69,
          height: 114,
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/innovation/section4-img5.png",
          width: 92,
          height: 114,
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/innovation/section4-img6.png",
          width: 89,
          height: 129,
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/innovation/section4-img7.png",
          width: 88,
          height: 129,
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/innovation/section4-img8.png",
          width: 96,
          height: 129,
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/innovation/section4-img9.png",
          width: 86,
          height: 129,
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/innovation/section4-img10.png",
          width: 87,
          height: 129,
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
      ],
      section5List: [
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/innovation/section5-img1.png",
          title: "定位引擎",
          subtitle: "Positioning engine",
          textArr: [
            "专业的定位技术架构",
            "软硬件一体化研发",
            "室内外多层无缝定位技术",
            "临床与实践的丰富应用案例",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/innovation/section5-img2.png",
          title: "地图引擎",
          subtitle: "MAP engine",
          textArr: [
            "室内外一体化无缝切换",
            "3D效果展现",
            "支持主流地图（卫星图、3D等）",
            "操作流畅、兼容多种系统",
            "跨平台、自学习、云控",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/innovation/section5-img3.png",
          title: "业务引擎",
          subtitle: "MAP engine",
          textArr: [
            "自定义业务模板、流程",
            "自定义审批权限管理",
            "慢病监控、实时预警",
            "大屏预警显示、电子围栏",
            "监测报警、实时预警",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/innovation/section5-img4.png",
          title: "AI计算引擎",
          subtitle: "MAP engine",
          textArr: [
            "动态视频智能识别分析",
            "静态图形量化分级诊断",
            "自身监测数据趋势分析",
            "横向生化数据辅助诊断",
            "生活活动感知深度学习",
          ],
        },
      ],
      sectionFourObj: null,
      carouselIndex: 0,
    };
  },
  methods: {
    handleShowDetail(index) {
      this.sectionFourObj = this.section4List[index];
    },
    handleHideDetail() {
      this.sectionFourObj = null;
    },
    changeCarousel(index) {
      this.carouselIndex = index;
      this.$refs.carousel.setActiveItem(index);
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  background: #eef1f5;
}
.horizontal-line {
  width: 73px;
  height: 5px;
  background: #90c446;
}
.title-box {
  .title {
    font-family: "UI-Bold";
    font-size: 52px;
    color: #1f3876;
  }
  .subtitle {
    font-family: "CN-Light";
    font-size: 31px;
    margin-top: 5px;
    color: #90c446;
  }
  .info {
    display: flex;
    margin-top: 29px;
    &-left {
      margin-right: 30px;
      img {
        width: 23px;
        height: 24px;
      }
    }
    &-right {
      flex: 1;
      font-family: "CN-Light";
      font-size: 21px;
      color: #727171;
    }
  }
}
.banner-box {
  position: relative;
  &-img {
    width: 100%;
  }
  &-text-box {
    position: absolute;
    width: 1200px;
    box-sizing: border-box;
    // left: 360px;
    top: 134px;
    left: 0;
    right: 0;
    margin: 0 auto;
    &-title {
      font-size: 60px;
      color: #1f3876;
      font-family: "UI-Bold";
      span {
        color: #90c446;
      }
    }
    &-tips {
      font-family: "CN-Light";
      color: #90c446;
      font-size: 31px;
      //   margin-top: 17px;
    }
    &-info {
      font-family: "CN-Light";
      font-size: 21px;
      line-height: 40px;
      color: #213763;
      width: 580px;
    }
    &-btn {
      width: 287px;
      height: 56px;
      margin-top: 50px;
      border-radius: 50px;
      cursor: pointer;
      transition: all 0.8s;
    }
    &-btn:hover {
      transform: translateY(-3px);
      box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.2);
    }
    &-txt {
      font-family: "CN-Light";
      font-size: 18px;
      color: #858585;
      margin-top: 30px;
    }
  }
}
.section1 {
  padding-top: 159px;
  padding-bottom: 100px;
  &-box {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    &-left {
      width: 485px;
      height: 655px;
      border-radius: 20px;
      .el-image {
        width: 100%;
        height: 100%;
        cursor: pointer;
        transition: all 0.8s;
      }
      .el-image:hover {
        transform: scale(1.02);
      }
    }
    &-right {
      width: 631px;
      &-title {
        font-family: "UI-Regular";
        font-size: 52px;
        color: #1f3876;
      }
      &-subtitle {
        font-family: "CN-Light";
        font-size: 31px;
        color: #90c446;
        margin-top: 16px;
      }
      &-info {
        margin-top: 59px;
        &-box {
          display: flex;
          justify-content: space-between;
          &-circle {
            width: 11px;
            height: 11px;
            background: #727171;
            border-radius: 50%;
            margin-top: 13px;
          }
          &-text {
            width: 603px;
            font-family: "CN-Light";
            font-size: 21px;
            color: #727171;
          }
        }
      }
    }
  }
}
.section2 {
  width: 100%;
  height: 1337px;
  background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/innovation/section2-bg.png") no-repeat;
  background-size: 100%;
  background-position: center;
  &-box {
    width: 1200px;
    margin: 0 auto;
    padding-top: 400px;
    &-info {
      font-family: "CN-Light";
      font-size: 21px;
      color: #1f3876;
      margin-top: 55px;
      width: 366px;
      line-height: 36px;
    }
    &-content {
      display: flex;
      justify-content: space-between;
      margin-top: 225px;
      &-img {
        width: 749px;
        height: 541px;
        cursor: pointer;
        transition: all 0.8s;
      }
      &-img:hover {
        transform: scale(1.02);
      }
      &-info {
        margin-top: 230px;
        &-title {
          display: flex;
          align-items: center;
          font-family: "CN-Medium";
          font-size: 32px;
          color: #1f3876;
          &-img {
            width: 23px;
            height: 23px;
            margin-left: 30px;
          }
        }
        &-text {
          width: 424px;
          margin-top: 16px;
          font-family: "CN-Light";
          font-size: 21px;
          color: #727171;
          text-align: justify;
        }
      }
    }
  }
}
.section3 {
  width: 1200px;
  margin: 0 auto;
  margin-top: 206px;
}
.section4 {
  position: relative;
  margin-top: 241px;
  &-img {
    position: absolute;
    right: 0;
    top: 200px;
    width: 50%;
    z-index: 0;
  }
  &-box {
    width: 1200px;
    margin: 0 auto;
    &-title-box {
      text-align: center;
      &-title {
        font-family: "UI-Regular";
        font-size: 52px;
        color: #1f3876;
      }
      &-subtitle {
        font-family: "CN-Light";
        font-size: 31px;
        color: #90c446;
        margin-top: 10px;
      }
    }
    &-card {
      width: 1200px;
      height: 535px;
      background: #ffffff;
      border-radius: 20px;
      box-shadow: 10.4px 9.37px 35px 14px rgba(0, 15, 33, 0.1);
      margin-top: 85px;
      box-sizing: border-box;
      padding: 100px 0;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-row-gap: 88px;
      position: relative;
      z-index: 1;
      cursor: pointer;
      &-item {
        display: flex;
        justify-content: center;
      }
    }
  }
}
.section5 {
  margin-top: 200px;
  padding-bottom: 324px;
  &-box {
    width: 1200px;
    margin: 0 auto;
    &-content {
      margin-top: 85px;
      display: flex;
      justify-content: space-between;
      &-left {
        width: 795px;
        height: 444px;
        border-radius: 22px;
        background: white;
        &-item {
          width: 100%;
          height: 100%;
          position: relative;
          &-box {
            padding-top: 75px;
            padding-left: 110px;
            color: white;
            &-title {
              font-family: "CN-Medium";
              font-size: 44px;
              line-height: 65px;
            }
            &-subtitle {
              font-size: 23px;
              font-family: "CN-Light";
              line-height: 28px;
              color: #90c446;
            }
            &-info {
              margin-top: 50px;
              &-item {
                display: flex;
                align-items: center;
                font-family: "CN-Light";
                font-size: 21px;
                line-height: 38px;
                &-circle {
                  width: 7px;
                  height: 7px;
                  background: white;
                  border-radius: 50%;
                  margin-right: 15px;
                }
              }
            }
          }
        }
        /deep/ .el-carousel__indicators--horizontal {
          bottom: 91px;
          right: 73px;
          left: auto;
          transform: none;
        }
        /deep/ .el-carousel__button {
          width: 18px;
          height: 7px;
          background: #ffffff;
          border-radius: 3px;
          padding: none !important;
          opacity: 1;
        }
        /deep/ .el-carousel__indicator.is-active button {
          width: 40px;
          background: #90c446;
        }
      }
      &-right {
        width: 371px;
        height: 440px;
        background: #ffffff;
        border-radius: 20px;
        box-shadow: 10.4px 9.37px 35px 14px rgba(0, 15, 33, 0.1);
        box-sizing: border-box;
        cursor: pointer;
        &-item {
          font-family: "CN-Medium";
          font-size: 32px;
          color: #1f3876;
          height: 110px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 55px;
          position: relative;
          &-bottom {
            width: 260px;
            height: 1px;
            background: #eeeeee;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
          }
        }
        &-active {
          background: #90c446;
          border-radius: 22px;
          box-shadow: -8px 0px 10px 8px rgba(0, 15, 33, 0.1);
          color: white;
        }
      }
    }
  }
}
.sectionItemBig {
  width: 1200px;
  height: 535px;
  background: white;
  border-radius: 20px;
  box-shadow: 10.4px 9.37px 35px 14px rgba(0, 15, 33, 0.1);
  margin-top: 85px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0px 139px 0 98px;
  cursor: pointer;
  .itemLeft {
    margin-right: 131px;
    text-align: center;
    font-family: "CN-Blod";
    font-size: 28px;
    margin-top: 30px;
    font-weight: bold;
    color: #1f3876;
    cursor: pointer;
    img {
      width: 159px;
    }
  }
  .itemRight {
    p {
      font-family: "CN-Light";
      font-size: 19px;
      line-height: 32px;
      color: #727171;
      margin-top: 40px;
      position: relative;
      // width: 644px;
    }
    p::before {
      width: 11px;
      height: 11px;
      content: "";
      background: #727171;
      border-radius: 50%;
      position: absolute;
      left: -23px;
      top: 12px;
    }
  }
}
</style>
